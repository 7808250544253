var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

/**
 * @license
 * Copyright 2015 Google Inc. All Rights Reserved.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *      http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
(function () {
  'use strict';
  /**
   * Class constructor for Button MDL component.
   * Implements MDL component design pattern defined at:
   * https://github.com/jasonmayes/mdl-component-design-pattern
   *
   * @param {HTMLElement} element The element that will be upgraded.
   */

  var MaterialButton = function MaterialButton(element) {
    (this || _global).element_ = element; // Initialize instance.

    this.init();
  };

  window["MaterialButton"] = MaterialButton;
  /**
   * Store constants in one place so they can be updated easily.
   *
   * @enum {string | number}
   * @private
   */

  MaterialButton.prototype.Constant_ = {// None for now.
  };
  /**
   * Store strings for class names defined by this component that are used in
   * JavaScript. This allows us to simply change it in one place should we
   * decide to modify at a later date.
   *
   * @enum {string}
   * @private
   */

  MaterialButton.prototype.CssClasses_ = {
    RIPPLE_EFFECT: "mdl-js-ripple-effect",
    RIPPLE_CONTAINER: "mdl-button__ripple-container",
    RIPPLE: "mdl-ripple"
  };
  /**
   * Handle blur of element.
   *
   * @param {Event} event The event that fired.
   * @private
   */

  MaterialButton.prototype.blurHandler_ = function (event) {
    if (event) {
      (this || _global).element_.blur();
    }
  }; // Public methods.

  /**
   * Disable button.
   *
   * @public
   */


  MaterialButton.prototype.disable = function () {
    (this || _global).element_.disabled = true;
  };

  MaterialButton.prototype["disable"] = MaterialButton.prototype.disable;
  /**
   * Enable button.
   *
   * @public
   */

  MaterialButton.prototype.enable = function () {
    (this || _global).element_.disabled = false;
  };

  MaterialButton.prototype["enable"] = MaterialButton.prototype.enable;
  /**
   * Initialize element.
   */

  MaterialButton.prototype.init = function () {
    if ((this || _global).element_) {
      if ((this || _global).element_.classList.contains((this || _global).CssClasses_.RIPPLE_EFFECT)) {
        var rippleContainer = document.createElement("span");
        rippleContainer.classList.add((this || _global).CssClasses_.RIPPLE_CONTAINER);
        (this || _global).rippleElement_ = document.createElement("span");

        (this || _global).rippleElement_.classList.add((this || _global).CssClasses_.RIPPLE);

        rippleContainer.appendChild((this || _global).rippleElement_);
        (this || _global).boundRippleBlurHandler = (this || _global).blurHandler_.bind(this || _global);

        (this || _global).rippleElement_.addEventListener("mouseup", (this || _global).boundRippleBlurHandler);

        (this || _global).element_.appendChild(rippleContainer);
      }

      (this || _global).boundButtonBlurHandler = (this || _global).blurHandler_.bind(this || _global);

      (this || _global).element_.addEventListener("mouseup", (this || _global).boundButtonBlurHandler);

      (this || _global).element_.addEventListener("mouseleave", (this || _global).boundButtonBlurHandler);
    }
  }; // The component registers itself. It can assume componentHandler is available
  // in the global scope.


  componentHandler.register({
    constructor: MaterialButton,
    classAsString: "MaterialButton",
    cssClass: "mdl-js-button",
    widget: true
  });
})();

export default {};